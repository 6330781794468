/* eslint-disable */
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['appTypeSelect', 'childSelectContainer', 'childSelect', 'submit']

  appTypeSelectTargetConnected() {
    // Triggers a change event on the appTypeSelect so the child inputs are populated when rendering the form after
    // a validation error
    if (this.appTypeSelectTarget.value !== '') {
      const event = new Event('change');
      this.appTypeSelectTarget.dispatchEvent(event)
    }
  }

  populateChildSelect(event) {
    this.resetChildSelects();

    const that = this
    const params = {
      refresh: {
        app_type: this.appTypeSelectTarget.value,
        selected_base_environment_name: event.params.selectedBaseEnvironmentName,
      },
    }

    $.get(event.params.url, params).done(function (data) {
      that.childSelectContainerTarget.innerHTML = data
      that.enableSubmit();
    })
  }

  resetChildSelects() {
    $(this.childSelectTargets).prop('disabled', (i, _v) => true);
    $(this.childSelectTargets).empty();
    $(this.childSelectTargets).append(new Option('Please wait ...'));
  }

  enableSubmit() {
    $(this.submitTarget).prop('disabled', (i, v) => false);
  }
}
